





























































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
import MainMenu from "@/components/WebUI/MainMenu.vue";
import Notice from "@/components/WebUI/Notice.vue";
import StoreNotice from "@/components/WebUI/StoreNotice.vue";
import GetSettingsBtn from "@/components/Btn/getSettings.vue";
import getParkingMgrBtn from "@/components/Btn/getParkingMgr.vue";
import HealthCheck from "@/components/Btn/HealthCheck.vue";

interface Params {
  servers: any;
  selectServer: number | null;
  parkings: any;
  host: string;
}
export default Vue.extend({
  data(): Params {
    return {
      servers: [],
      selectServer: null,
      parkings: [],
      host: "",
    }
  },
  computed: {
    isHome(): any {
      return this.$route.path === '/home';
    }
  },
  components: {
    MainMenu,
    Notice,
    StoreNotice,
    GetSettingsBtn,
    getParkingMgrBtn,
    HealthCheck
  },
  methods: {
    async getHost(): Promise<void> {
      this.host = "" // データの初期化
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/get_host`;
      const formData = { params: { id: this.selectServer } };
      return await axios
      .get(url, formData)
      .then(res => {
        this.host = res.data.host
        store.commit('setServer', this.selectServer)
      })
      .catch(error => {
        console.log(error)
        alert("ホスト情報の取得に失敗しました");
      });
    },
    async getServerInfo(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers`;
      const formData = { params: { demoSelect: true } }
      return await axios
      .get(url, formData)
      .then(res => {
        this.servers = res.data;
      })
      .catch(error => {
        console.log(error)
        alert("サーバー情報の取得に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide()
      })
      ;
    },
  },
  mounted() {
    this.getServerInfo();
  }
})
