










































































































































































import Vue from "vue";
import axios from "axios";

interface UpdateCheckOnTheDay {
  // セレクトボックスで使用する物件一覧
  servers:         any;
  selectParkings:  any;
  result:          any;
  version:         strings;
  userId:          strings;
}
export default Vue.extend({
  data(): UpdateCheckOnTheDay {
    return {
      servers:        [],
      selectParkings: [],
      result:         [],
      version:        "0.0.0.0",
      userId:         JSON.parse(localStorage.user).id
    }
  },
  methods: {
    // 物件情報取得
    async getServerInfo(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers`;
      const formData = { params: { sshFlg: true } }
      return await axios
      .get(url, formData)
      .then(res => {
        this.servers = res.data;
        console.log(this.servers)
        this.$vloading.hide();
      })
      .catch(error => {
        console.log(error)
        alert("サーバー情報の取得に失敗しました");
        this.$vloading.hide();
      });
    },
    // s3アップロード開始
    async screenShotUpLoad(): Promise<void> {
      this.result = []
      this.$vloading.show()
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/local_screen_shot_images_upload`
      const formData = {
                        selectedParkingIds: this.selectParkings,
                        userId: JSON.parse(localStorage.user).id,
                        userName: JSON.parse(localStorage.user).name
                        }
      return await axios
      .post(url, formData)
      .then(res => {
        console.log(res);
        this.result = res.data
        this.$vloading.hide();
      })
      .catch(error => {
        console.log(error);
        alert("確認に失敗しました。")
        this.$vloading.hide();
      });
    },
  },
  mounted() {
    this.getServerInfo();
  }
});
