










































































































































import Vue from "vue";
import axios from "axios";
import TextHighlight from 'vue-text-highlight';
Vue.component('text-highlight', TextHighlight);
interface UpdateCheckTheDayBefore {
  // セレクトボックスで使用する物件一覧
  servers:         any;
  selectParkings:  any;
  result:          any;
  zoom:            number;
  queries:         any;
  keywordArray:    strings;
}
export default Vue.extend({
  data(): UpdateCheckTheDayBefore {
    return {
      servers:        [],
      selectParkings: [],
      result:         [],
      zoom:           100,
      queries:        [],
      keywordArray:   "",
    }
  },
  methods: {
    // 物件情報取得
    async getServerInfo(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/servers`;
      const formData = { params: { sshFlg: 5 } }
      return await axios
      .get(url, formData)
      .then(res => {
        this.servers = res.data;
        console.log(this.servers)
        this.$vloading.hide();
      })
      .catch(error => {
        console.log(error)
        alert("サーバー情報の取得に失敗しました");
        this.$vloading.hide();
      });
    },
    // 確認開始
    async checkSQL(): Promise<void> {
      const user     = localStorage.user
      this.userName  = JSON.parse(user).name
      this.result    = []
      this.$vloading.show()
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/update_check_the_day_before`
      const formData = { selectedParkingIds: this.selectParkings, user: this.userName }
      return await axios
      .post(url, formData)
      .then(res => {
        console.log(res);
        this.result = res.data
        this.$vloading.hide();
      })
      .catch(error => {
        console.log(error);
        alert("確認に失敗しました。")
        this.$vloading.hide();
      });
    },
    // ズームアウト
    zoomOut () {
      this.zoom = (this.zoom - 10) || 0
    },
    // ズームイン
    zoomIn () {
      this.zoom = (this.zoom + 10) || 100
    },
    // キーワードを登録する
    keywordPush() {
      this.queries.push(this.keywordArray)
      this.keywordArray = ""
    },
    // 登録したキーワードをリセットする
    keywordReset() {
      this.queries = []
    },
  },
  mounted() {
    this.getServerInfo();
  }
});
